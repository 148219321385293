import React from "react";
import "./Main.css";
import about from "./assets/about_cover.jpeg";
import diprism from "./assets/diprism.jpeg";
import support from "./assets/support.jpg";
import coding from "./assets/coding.webp";

export const Main = () => {
  return (
    <div className="container">
      <section id="about">
        <div className="column-center">
          <p className="title">About</p>
          <p className="content">
            <span className="company-name">AG Domo Tech Sdn Bhd</span>
            {` is founded in May 2022 by Alex Gui & Emi Chua\n `}
          </p>
          <p className="content">
            Two Passionate Individual to want to bridge the gap of Education and
            Tech. Our company mission is aim to bridge the gap of Education and
            Tech through EduTech. We have strong believe that our solutions can
            offer the best experience for both educator and students
          </p>
          <p className="content">
            We have partnered with DMC Consultancy to develop the one stop
            education platform <span className="company-name">DiPrism</span>
          </p>
        </div>
        <div className="column-center">
          <img src={about} />
        </div>
      </section>

      <div className="divider" />

      <section id="portfolio">
        <div className="column-center">
          <img src={diprism} />
        </div>
        <div className="column-center">
          <p className="title">DiPrism</p>
          <p className="content">
            <span className="company-name">DiPrism</span>
            {` is a product developed by our company for DMC Consultancy Sdn Bhd `}
          </p>
          <p className="content">
            From the brainchild of DMC Consultancy Sdn Bhd CEO Charles Gregory.
            He decided to create a platform to can revolutionize the training
            industry with async learning
          </p>
          <p className="content">
            Unlike conventional product, DiPrism is aim to help students not
            only learning efficiently but able to implement what they have learn
            through series of video and project in the real world industry. All
            courses are make sure to be relevant and practical in the real world
            enviroment
          </p>
        </div>
      </section>

      <div className="divider" />

      <section id="solution">
        <div className="column-center">
          <p className="title">Fast &amp; Efficient</p>

          <p className="content">
            Using cloud technology and rapid development, we are able to deliver
            product faster and more efficient. In our company we practice Agile
            methodology for rapid product iteration
          </p>
          <p className="content">
            We work closely with our partner to streamline the process of
            requirement and development. Typical process will be Design ->
            Develop -> Test -> Deliver
          </p>
        </div>
        <div className="column-center">
          <img src={coding} style={{ width: "80%" }} />
        </div>
      </section>

      <section>
        <div className="column-center">
          <img src={support} />
        </div>
        <div className="column-center">
          <p className="title">Support</p>

          <p className="content">
            We truly believe support is crucial for client success. Without a
            proper support, client cannot have a great product. Thats why we
            have put support as one of our core values
          </p>
          <p className="content">
            Critical issues will be resolved immediately to ensure minimum
            downtime and least affected on user experience.
          </p>
        </div>
      </section>

      <section id="contact" className="contact">
        <div>
          <p className="title">Contact</p>

          <form method="post" target="_blank">
            <div className="form-row">
              <label>
                <span>Name:</span>
              </label>
              <input type="text" />
            </div>
            <div className="form-row">
              <label>Email:</label>
              <input type="text" />
            </div>
            <div className="form-row">
              <label for="textarea">Message:</label>
              <textarea id="textarea" rows="10" cols="150" type="text" />
            </div>
            <div className="form-row">
              <div />
              <button type="button" onClick={(e) => {}}>
                Submit
              </button>
            </div>
          </form>
        </div>
      </section>
    </div>
  );
};
