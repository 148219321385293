import React from "react";
import "./Footer.css";

export const Footer = () => {
  return (
    <div className="nav-container">
      <div className="footer-container">
        <p className="footer">Copyright©2022 AG Domo Tech Sdn. Bhd.</p>
      </div>
    </div>
  );
};
