import React from "react";
import "./TopNavBar.css";
import { Link, animateScroll as scroll } from "react-scroll";

export const TopNavBar = () => {
  return (
    <div className="nav-container">
      <nav>
        <ul className="nav-header">
          <li>
            <Link
              smooth={true}
              offset={-70}
              duration={500}
              to="about"
              className="link"
            >
              About
            </Link>
          </li>

          <li>
            <Link
              smooth={true}
              offset={-70}
              duration={500}
              to="portfolio"
              className="link"
            >
              Portfolio
            </Link>
          </li>

          <li>
            <Link
              smooth={true}
              offset={-70}
              duration={500}
              to="solution"
              className="link"
            >
              Solutions
            </Link>
          </li>

          <li>
            <Link
              smooth={true}
              offset={-70}
              duration={500}
              to="contact"
              className="link"
            >
              Contact
            </Link>
          </li>
        </ul>
      </nav>
    </div>
  );
};
